<template>
  <div class="q-pa-xl">
    <!-- export -->
    <q-card flat bordered>
      <q-card-section class="row justify-end bg-grey-1">
        <q-btn flat icon="menu">
          <q-menu>
            <q-list>
              <q-item clickable v-close-popup @click="exportToSheet">
                <q-item-section>export ke excel</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-card-section>
      <q-markup-table flat bordered id="tableDummy">
        <thead>
          <tr class="text-left">
            <th>no</th>
            <th>name</th>
            <th>score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Abiyoga Aaron Sulistyo</td>
            <td>100</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jordy Jonathan</td>
            <td>82</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Yustinus Vernanda</td>
            <td>90</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <!-- end of export  -->

    <!-- import  -->
    <q-card flat bordered class="q-mt-xl">
      <q-card-section class="row justify-end bg-grey-1">
        <q-file
          outlined
          @input="importFromSheet"
          label="import excel"
          stack-label
          style="max-width:300px"
          v-model="file"
        >
          <template v-slot:prepend>
            <q-icon name="cloud_upload" />
          </template>
        </q-file>
        <!-- <q-input outlined></q-input> -->
      </q-card-section>
      <q-markup-table>
        <thead>
          <tr class="text-left">
            <th>no</th>
            <th>name</th>
            <th>score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in  json" :key="i">
            <td>{{val['no']}}</td>
            <td>{{val['name']}}</td>
            <td>{{val['score']}}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <!-- end of import  -->
  </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
  data() {
    return {
      json: [],
      file: null,
    }
  },
  methods: {
    exportToSheet() {
      var tb = document.getElementById('tableDummy')
      var ws = XLSX.utils.table_to_sheet(tb)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb,ws,'tableDummy')
      XLSX.writeFile(wb,'demoExport.xlsx')
    },
    importFromSheet(file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        var bin=""
        var bytes=new Uint8Array(e.target.result)
        var len = bytes.byteLength
        
        for(var i=0;i<len;i++) {
          bin += String.fromCharCode(bytes[i])
        }

        var wb = XLSX.read(bin, {type: 'binary'})
        var name = wb.SheetNames[0]
        var ws = wb.Sheets[name]
        var json = XLSX.utils.sheet_to_json(ws)
        this.json = json
      }
      if(!file) return
      reader.readAsArrayBuffer(file)

    }
  }
}
</script>